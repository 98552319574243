import moment from "moment";
import api from "./api";

const isClient = typeof window === "object";

const simpleGetBlobRequest = function (url, cacheKey, cancelTokenSource) {
    if (cacheKey && cacheKey.length > 0) {
        let cache = window.localStorage.getItem("cache-" + cacheKey);
        if (cache) {
            return new Promise((resolve, reject) => {
                resolve(JSON.parse(cache));
            });
        }
    }
    return api
        .get(
            url,
            {
                responseType: "blob",
                headers: {
                    Authorization: "Bearer " + (isClient ? window.localStorage.getItem("session-token") : ""),
                },
            },
            cancelTokenSource
        )
        .then(response => {
            /*if (cacheKey) {
                window.localStorage.setItem("cache-" + cacheKey, JSON.stringify(response.data));
            }*/
            return response;
        });
};

const simpleGetRequest = function (url, cacheKey, cancelTokenSource) {
    if (cacheKey && cacheKey.length > 0) {
        let cache = window.localStorage.getItem("cache-" + cacheKey);
        if (cache) {
            return new Promise((resolve, reject) => {
                resolve(JSON.parse(cache));
            });
        }
    }
    return api
        .get(
            url,
            {
                headers: {
                    Authorization: "Bearer " + (isClient ? window.localStorage.getItem("session-token") : ""),
                },
            },
            cancelTokenSource
        )
        .then(response => {
            if (cacheKey) {
                window.localStorage.setItem("cache-" + cacheKey, JSON.stringify(response.data));
            }
            return response.data;
        });
};

const simpleDeleteRequest = function (url) {
    return api
        .delete(url, {
            headers: {
                Authorization: "Bearer " + (isClient ? window.localStorage.getItem("session-token") : ""),
            },
        })
        .then(response => {
            return response.data;
        });
};

const simplePostRequest = async function (url, data) {
    const response = await api
        .post(url, data, {
            headers: {
                Authorization: "Bearer " + (isClient ? window.localStorage.getItem("session-token") : ""),
            },
        });
    return response.data;
};

const uploadFiles = function (url, data, config = {}) {
    return api
        .post(url, data, {
            ...config,
            headers: {
                Authorization: "Bearer " + (isClient ? window.localStorage.getItem("session-token") : ""),
            },
        })
        .then(response => {
            return response.data;
        });
};

const generateFilterString = function (data) {
    if (!data) {
        return "";
    }

    var str = "";
    if (data.search && data.search.length > 0) {
        // Se enviado o search, todos os outros filtros são desconsiderados
        str += "search=" + data.search + "&";

        if (data.sortProcessComment) {
            str += "sort=" + data.sortProcessComment + "&";
        }

        //Ignorar outros filtros apenas se não for a tela de lista de processos ou marcado pra ignorar
        if (data.ignore || typeof data.ignore === "undefined") {
            return str;
        }
    }

    if (data.filters) {
        if (data.filters.stages && data.filters.stages.length > 0) {
            for (let i in data.filters.stages) {
                str += "stages[]=" + data.filters.stages[i] + "&";
            }
        }

        if (data.filters.clients && data.filters.clients.length > 0) {
            for (let i in data.filters.clients) {
                str += "clients[]=" + data.filters.clients[i] + "&";
            }
        }

        if (data.filters.channels && data.filters.channels.length > 0) {
            for (let i in data.filters.channels) {
                str += "channels[]=" + data.filters.channels[i] + "&";
            }
        }

        if (data.filters.transportModalities && data.filters.transportModalities.length > 0) {
            for (let i in data.filters.transportModalities) {
                str += "transportModalities[]=" + data.filters.transportModalities[i] + "&";
            }
        }

        if (data.filters.processModalities && data.filters.processModalities.length > 0) {
            for (let i in data.filters.processModalities) {
                str += "processModalities[]=" + data.filters.processModalities[i] + "&";
            }
        }

        if (data.filters.startsAt) {
            str += "startsAt=" + data.filters.startsAt + "&";
        }

        if (data.filters.endsAt) {
            str += "endsAt=" + data.filters.endsAt + "&";
        }

        if (data.filters.originPort) {
            str += "originPort=" + data.filters.originPort + "&";
        }

        if (data.filters.destinationPort) {
            str += "destinationPort=" + data.filters.destinationPort + "&";
        }

        if (data.filters.consignatario) {
            str += "consignatario=" + data.filters.consignatario + "&";
        }

        if (data.filters.originCountry) {
            str += "originCountry=" + data.filters.originCountry + "&";
        }

        if (data.filters.hscode) {
            str += "hscode=" + data.filters.hscode + "&";
        }

        if (data.filters.mercadoria) {
            str += "mercadoria=" + data.filters.mercadoria + "&";
        }

        if (data.filters.cargaType) {
            str += "cargaType=" + data.filters.cargaType + "&";
        }

        if (data.filters.selectedStatus) {
            str += "status=" + data.filters.selectedStatus + "&";
        }
    }

    if (typeof data.parent === "number") {
        str += "parent=" + data.parent + "&";
    }

    if (typeof data.isRootDocumentNumber === "number") {
        str += "isRootDocumentNumber=" + data.isRootDocumentNumber + "&";
    }

    if (data.startsAt) {
        str += "startsAt=" + data.startsAt + "&";
    }

    if (data.endsAt) {
        str += "endsAt=" + data.endsAt + "&";
    }

    if (data.period) {
        if (data.period.startsAt) {
            str += "startsAt=" + data.period.startsAt + "&";
        }
        if (data.period.endsAt) {
            str += "endsAt=" + data.period.endsAt + "&";
        }
    }

    if (data.sortColumn) {
        str += "sort=" + data.sortColumn + "&";
    }

    if (data.sortProcessComment) {
        str += "sort=" + data.sortProcessComment + "&";
    }

    if (data.sortDirection) {
        str += "direction=" + data.sortDirection + "&";
    }

    if (data.client) {
        str += "client=" + data.client + "&";
    }

    if (data.cteNumber) {
        str += "cteNumber=" + data.cteNumber + "&";
    }

    if (data.statusUser) {
        str += "statusUser=" + data.statusUser + "&";
    }

    if (data.auto) {
        str += "auto=" + data.auto.mode + "&";
    }

    if (data.nfeNumber) {
        str += "nfeNumber=" + data.nfeNumber + "&";
    }

    if (data.type_storage) {
        str += "type_storage=" + data.type_storage + "&";
    }

    if (data.idArmazem) {
        str += "idArmazem=" + data.idArmazem + "&";
    }

    if (data.periodPhotos) {
        str += "periodPhotos=" + data.periodPhotos + "&";
    }

    if (data.statusPedido) {
        str += "statusPedido=" + data.statusPedido + "&";
    }

    if (data.statusGrafico) {
        str += "statusGrafico=" + data.statusGrafico + "&";
    }

    if (data.dashboard) {
        str += "dashboard=" + data.dashboard + "&";
    }

    if (data.documentNumber) {
        str += "documentNumber=" + data.documentNumber + "&";
    }

    if (data.order) {
        if (data.order.order_campo && data.order.order) {
            str += "order_campo=" + data.order.order_campo + "&";
            str += "order=" + data.order.order + "&";
        }
    }

    if (data?.clientsWarehouse && data?.clientsWarehouse?.length > 0) {
        for (let i in data.clientsWarehouse) {
            str += "clients[]=" + data.clientsWarehouse[i] + "&";
        }
    }

    return str;
};

const generatePaginationString = function (page) {
    var str = "";
    if (page) {
        str += "page=" + page + "&";
    }

    return str;
};

const generatePageSizeString = function (pageSize) {
    var str = "";
    if (pageSize) {
        str += "page_size=" + pageSize + "&";
    }

    return str;
};

// const generateSortString = function (sort) {
//     var str = "";
//     if (sort) {
//         str = "&sort=" + sort.sort + "&direction=" + sort.direction;
//     }

//     return str;
// };

const requests = {
    getApiUrl() {
        return process.env.REACT_APP_BASE_URL;
    },
    // Generic
    wait() {
        return simpleGetRequest(`/profile/wait`);
    },
    get(url) {
        return simpleGetRequest(url);
    },
    post(url, data) {
        return simplePostRequest(url, data);
    },
    // auth
    logout() {
        return simpleDeleteRequest(`/profile/logout`);
    },
    // Dashboards
    dashboardProcessSla(filters) {
        let filterString = generateFilterString(filters);
        return simpleGetRequest(`/dashboard/process/sla?${filterString}`);
    },
    dashboardProcessByStage(filters) {
        let filterString = generateFilterString(filters);
        return simpleGetRequest(`/dashboard/process/stage?${filterString}`);
    },
    dashboardProcessByChannel(filters) {
        let filterString = generateFilterString(filters);
        return simpleGetRequest(`/dashboard/process/channel?${filterString}`);
    },
    dashboardProcessByModality(filters) {
        let filterString = generateFilterString(filters);
        return simpleGetRequest(`/dashboard/process/modality?${filterString}`);
    },
    dashboardProcessFob(filters) {
        let filterString = generateFilterString(filters);
        return simpleGetRequest(`/dashboard/process/fob-sum?${filterString}`);
    },
    dashboardProcessBilling(filters) {
        let filterString = generateFilterString(filters);
        return simpleGetRequest(`/dashboard/process/billing-sum?${filterString}`);
    },
    atualizationsExport(fileModality = "all", filters) {
        let filterString = generateFilterString(filters);
        return simpleGetRequest(`/adm/data-update/${fileModality}/qtt?${filterString}`);
    },
    atualizationsQtt(filters) {
        let filterString = generateFilterString(filters);
        return simpleGetRequest(`/adm/data-update/qtt?${filterString}`);
    },
    dashboardBoardings(filters) {
        let filterString = generateFilterString(filters);
        return simpleGetRequest(`/dashboard/process/boardings?${filterString}`);
    },
    dashboardClientTax(filters) {
        let filterString = generateFilterString(filters);
        return simpleGetRequest(`/dashboard/client/tax?${filterString}`);
    },
    mapPositions(processIdentifier, filters) {
        let identifierString = processIdentifier ? `identifier=${processIdentifier}` : "";
        let filterString = generateFilterString(filters);

        return simpleGetRequest(`/dashboard/process/positions?${identifierString}${filterString}`);
    },
    // Armazém - Picking Packing ou Expedição
    dashboardPickingPack(filters, type) {
        let filterString = generateFilterString(filters);
        return simpleGetRequest(`/pickingepacking/metrics/${type}?${filterString}`);
    },
    remittanceAnalysisExpedition(filters, type) {
        let filterString = generateFilterString(filters);
        return simpleGetRequest(`/pickingepacking/remittance/?${filterString}`);
    },
    listPickingPack(filters, page) {
        let filterString = generateFilterString(filters);
        let paginationString = generatePaginationString(page);

        return simpleGetRequest(`/pickingepacking/list?${filterString}${paginationString}&page_size=15`);
    },
    exportListPickingExpedicao(filters, page) {
        let filterString = generateFilterString(filters);

        return simpleGetBlobRequest(`/pickingepacking/download/pickingpack?${filterString}`);
    },
    // Driver information
    addDriverInformation(data) {
        return simplePostRequest(`/information/new`, data);
    },
    uploadFileDriverInformation(identifier, data) {
        return simplePostRequest(`/open/information/${identifier}/file/upload`, data);
    },
    deleteFileDriverInformation(identifier, fileIdentifier) {
        return simpleDeleteRequest(`/open/information/${identifier}/file/${fileIdentifier}/remove`);
    },
    editDriverInformation(identifier, data) {
        return simplePostRequest(`/information/${identifier}/edit`, data);
    },
    showDriverInformation(identifier) {
        return simpleGetRequest(`/information/${identifier}/show`);
    },
    // Driver Freight
    listDriverFreightKanban(filters, page) {
        let filterString = generateFilterString(filters);
        let paginationString = generatePaginationString(page);

        return simpleGetRequest(`/adm/driver-freight/list?${filterString}${paginationString}&page_size=50`);
    },
    listDriverFreight(filters, page) {
        let filterString = generateFilterString({ search: filters.search });
        let paginationString = generatePaginationString(page);

        return simpleGetRequest(`/adm/driver-freight/list?${filterString}${paginationString}`);
    },
    addDriverFreight(data) {
        return simplePostRequest(`/adm/driver-freight/new`, data);
    },
    editDriverFreight(identifier, data) {
        return simplePostRequest(`/adm/driver-freight/${identifier}/edit`, data);
    },
    deleteDriverFreight(identifier) {
        return simpleDeleteRequest(`/adm/driver-freight/${identifier}/remove`);
    },
    showDriverFreight(identifier) {
        return simpleGetRequest(`/adm/driver-freight/${identifier}/show`);
    },
    showCheckpointsDriverFreight(identifier) {
        return simpleGetRequest(`/adm/driver-freight/${identifier}/checkpoints/show`);
    },
    showEventsDriverFreight(identifier) {
        return simpleGetRequest(`/adm/driver-freight/${identifier}/events/show/app`);
    },
    showDriver(identifier) {
        return simpleGetRequest(`/adm/driver/${identifier}/show`);
    },
    // Vehicles
    listVehicles(filters, page, pageSize) {
        let filterString = generateFilterString(filters);
        let paginationString = generatePaginationString(page);
        let pageSizeString = generatePageSizeString(pageSize);

        return simpleGetRequest(`/adm/vehicle/list?${filterString}${paginationString}${pageSizeString}`);
    },
    deleteVehicle(identifier) {
        return simpleDeleteRequest(`/adm/vehicle/${identifier}/remove`);
    },
    // Armazém - Estoque
    dashboardEstoque(filters) {
        let filterString = generateFilterString(filters);
        return simpleGetRequest(`/estoque/metrics?${filterString}`);
    },
    listEstoque(filters, page) {
        let filterString = generateFilterString(filters);
        let paginationString = generatePaginationString(page);

        return simpleGetRequest(`/estoque/list?${filterString}${paginationString}&page_size=15`);
    },
    exportListEstoque(filters, page) {
        let filterString = generateFilterString(filters);

        return simpleGetBlobRequest(`/estoque/download/estoque?${filterString}`);
    },
    savePhotosUploads(identifier, data, config) {
        return uploadFiles(`/estoque/file/${identifier}/upload`, data, config);
    },
    getFilesListByEstoque(filters, identifier, page) {
        let filterString = generateFilterString(filters);
        return simpleGetRequest(`/estoque/file/${identifier}/list?${filterString}`);
    },
    // Armazém - Recebimento
    dashboardRecebimento(filters) {
        let filterString = generateFilterString(filters);
        return simpleGetRequest(`/recebimento/metrics?${filterString}`);
    },
    listRecebimento(filters, page) {
        let filterString = generateFilterString(filters);
        let paginationString = generatePaginationString(page);

        return simpleGetRequest(`/recebimento/list?${filterString}${paginationString}&page_size=15`);
    },
    exportListRecebimento(filters, page) {
        let filterString = generateFilterString(filters);

        return simpleGetBlobRequest(`/recebimento/download/recebimento?${filterString}`);
    },
    // Freight
    listFreights(filters, page) {
        let filterString = generateFilterString(filters);
        let paginationString = generatePaginationString(page);

        return simpleGetRequest(`/freight/list?${filterString}${paginationString}&page_size=10`);
    },
    addFreightComment(identifier, data) {
        return simplePostRequest(`/freight/comment/${identifier}/send`, data);
    },
    getFreightComments(identifier) {
        return simpleGetRequest(`/freight/comment/${identifier}/list?`);
    },
    getFreightPositions(identifier) {
        return simpleGetRequest(`/freight/positions/${identifier}/list?`);
    },
    removeFreightComment(identifier) {
        return simpleDeleteRequest(`/freight/comment/${identifier}/remove`);
    },
    changeFreightVisibility(identifier, data) {
        return simplePostRequest(`/freight/${identifier}/client-visibility`, data);
    },
    // Requests
    requestByCode(filters) {
        let filterString = generateFilterString(filters);
        return simpleGetRequest(`/adm/request/count-by-status?${filterString}`);
    },
    requestByRole(filters) {
        let filterString = generateFilterString(filters);
        return simpleGetRequest(`/adm/request/count-by-role?${filterString}`);
    },
    requestLogs(filters, page) {
        let filterString = generateFilterString(filters);
        let paginationString = generatePaginationString(page);
        return simpleGetRequest(`/adm/request/list?${filterString}${paginationString}`);
    },
    requestRankingByUser(filters, page) {
        let filterString = generateFilterString(filters);
        let paginationString = generatePaginationString(page);
        return simpleGetRequest(`/adm/request/ranking-by-user?${filterString}${paginationString}`);
    },
    requestSum(filters, page) {
        let filterString = generateFilterString(filters);
        let paginationString = generatePaginationString(page);
        return simpleGetRequest(`/adm/request/sum?${filterString}${paginationString}`);
    },
    requestRankingByRoute(filters, page) {
        let filterString = generateFilterString(filters);
        let paginationString = generatePaginationString(page);
        return simpleGetRequest(`/adm/request/ranking-by-route?${filterString}${paginationString}`);
    },
    // Aditional Fields
    listAditionalFields(filters, page, pageSize) {
        let filterString = generateFilterString(filters);
        let paginationString = generatePaginationString(page);
        let pageSizeString = generatePageSizeString(pageSize);

        return simpleGetRequest(
            `/process/additional-field/list?page_size=15&${filterString}${paginationString}${pageSizeString}`
        );
    },
    listLogApis(filters, page, pageSize) {
        let filterString = generateFilterString(filters);
        let paginationString = generatePaginationString(page);
        let pageSizeString = generatePageSizeString(pageSize);

        return simpleGetRequest(`/adm/request/list?page_size=15&${filterString}${paginationString}${pageSizeString}`);
    },
    listCountLogApis(filters) {
        let filterString = generateFilterString(filters);

        return simpleGetRequest(`/adm/request/count&${filterString}`);
    },
    addAditionalField(data) {
        return simplePostRequest(`/process/additional-field/new`, data);
    },
    showAditionalField(identifier) {
        return simpleGetRequest(`/process/additional-field/${identifier}/show`);
    },
    editAditionalField(identifier, data) {
        return simplePostRequest(`/process/additional-field/${identifier}/edit`, data);
    },
    deleteAditionalField(identifier) {
        return simpleDeleteRequest(`/process/additional-field/${identifier}/remove`);
    },
    // Clients
    listClients(filters, page, pageSize) {
        let filterString = generateFilterString(filters);
        let paginationString = generatePaginationString(page);
        let pageSizeString = generatePageSizeString(pageSize);

        return simpleGetRequest(`/client/list?page_size=15&${filterString}${paginationString}${pageSizeString}`);
    },
    // Manage Clients
    listClientsManage(filters, page, pageSize) {
        let filterString = generateFilterString(filters);
        let paginationString = generatePaginationString(page);
        let pageSizeString = generatePageSizeString(pageSize);

        return simpleGetRequest(`/client/list/gestao?${filterString}${paginationString}${pageSizeString}`);
    },
    listAllClients(search = "") {
        return simpleGetRequest(`/client/list/all?search=${search}`);
    },
    listClientsWithPowerBiUrl() {
        return simpleGetRequest(`/client/list/power-bi`);
    },
    listClientsWithArmazem() {
        return simpleGetRequest(`/client/list/armazem`);
    },
    showClient(identifier) {
        return simpleGetRequest(`/client/${identifier}/show`);
    },
    deleteClient(identifier) {
        return simpleDeleteRequest(`/client/${identifier}/remove`);
    },
    deletePickingPack(identifier) {
        return simpleDeleteRequest(`/pickingepacking/${identifier}/remove`);
    },
    reativeClient(identifier) {
        return simpleGetRequest(`/client/${identifier}/reactivate`);
    },
    addClient(data) {
        return simplePostRequest(`/client/new`, data);
    },
    editClient(identifier, data) {
        return simplePostRequest(`/client/${identifier}/edit`, data);
    },
    // Faq
    addFaq(data) {
        return simplePostRequest(`/adm/faq/new`, data);
    },
    editFaq(identifier, data) {
        return simplePostRequest(`/adm/faq/${identifier}/edit`, data);
    },
    listFaqs() {
        return simpleGetRequest(`/faq/list`);
    },
    getFaq(identifier) {
        return simpleGetRequest(`/faq/${identifier}/show`);
    },
    deleteFaq(identifier) {
        return simpleDeleteRequest(`/adm/faq/${identifier}/remove`);
    },
    // Process
    getFilters() {
        return simpleGetRequest(`/filter/list`, "filters-list");
    },
    listProcessKanban(filters, page) {
        let filterString = generateFilterString(filters);
        let paginationString = generatePaginationString(page);

        return simpleGetRequest(`/process/list?${filterString}${paginationString}&page_size=50`);
    },
    listProcess(filters, page, cancelTokenSource, pageSize = 10) {
        let filterString = generateFilterString(filters);
        let paginationString = generatePaginationString(page);

        return simpleGetRequest(`/process/list?${filterString}${paginationString}&page_size=${pageSize}`, null, cancelTokenSource);
    },
    countProcess(filters, cancelTokenSource) {
        let filterString = generateFilterString(filters);

        return simpleGetRequest(`/process/count?${filterString}`, null, cancelTokenSource);
    },
    listMap(data, page) {
        let paginationString = generatePaginationString(page);
        return simpleGetRequest(
            `/map-filter/list-map?${paginationString}filters=${JSON.stringify(data) === "{}" ? null : JSON.stringify(data)}`
        );
    },
    changeProcessVisibility(identifier, data) {
        return simplePostRequest(`/process/${identifier}/process-visibility`, data);
    },
    listSelection(page) {
        let paginationString = generatePaginationString(page);
        return simpleGetRequest(`/map-filter/list?${paginationString}`);
    },
    deleteSelection(id) {
        return simpleDeleteRequest(`/map-filter/${id}/remove`);
    },
    newSelectionMap(values, filters, fields) {
        filters.fields = fields;

        let data = { ...values, filters };

        data.filters = JSON.stringify(data.filters);

        return simplePostRequest(`/map-filter/new`, data);
    },
    listImports(filters, page) {
        let filterString = generateFilterString(filters);
        let paginationString = generatePaginationString(page);

        return simpleGetRequest(`/adm/data-update/list?${filterString}${paginationString}`);
    },
    showProcess(identifier) {
        return simpleGetRequest(`/process/${identifier}/show`);
    },
    calendar(filters) {
        let filterString = generateFilterString(filters);
        return simpleGetRequest(`/calendar/process?${filterString}`);

        // let startsAtString = startsAt ? `startsAt=${startsAt}&` : '';
        // return simpleGetRequest(`/calendar/process?${startsAtString}${filterString}`);
    },
    week(filters) {
        let filterString = generateFilterString(filters);
        return simpleGetRequest(`/calendar/process?week=true&${filterString}`);
    },
    lastComments(filters, page, pageSize = 25) {
        let filterString = generateFilterString(filters);
        let paginationString = generatePaginationString(page);
        return simpleGetRequest(`/process/comment/list/all?${filterString}${paginationString}page_size=${pageSize}`);
    },
    listUpdates(filters, page, pageSize = 25) {
        let filterString = generateFilterString(filters);
        let paginationString = generatePaginationString(page);
        return simpleGetRequest(`/process/update/list?${filterString}${paginationString}page_size=${pageSize}`);
    },
    getProcessUpdates(identifier) {
        return simpleGetRequest(`/process/${identifier}/updates`);
    },
    getProcessComments(identifier, filters, cancelTokenSource) {
        let filterString = generateFilterString(filters);
        return simpleGetRequest(`/process/comment/${identifier}/list?${filterString}`, null, cancelTokenSource);
    },
    removeProcessComments(processIdentifier, fileIdentifier) {
        return simpleDeleteRequest(`/process/comment/${processIdentifier}/${fileIdentifier}/remove`);
    },
    addProcessStageComment(identifier, stage, data) {
        return simplePostRequest(`/process/comment/${identifier}/send?stage=${stage}`, data);
    },
    addProcessComment(identifier, data) {
        return simplePostRequest(`/process/comment/${identifier}/send`, data);
    },
    editProcessComment(processIdentifier, fileIdentifier, data) {
        return simplePostRequest(`/process/comment/${processIdentifier}/${fileIdentifier}/edit`, data);
    },
    getProcessItem(identifier, page) {
        let paginationString = generatePaginationString(page);

        return simpleGetRequest(`/process/item/${identifier}/list?${paginationString}`);
    },
    getProcessQuote(identifier, page) {
        let paginationString = generatePaginationString(page);

        return simpleGetRequest(`/process/quote/${identifier}/list?${paginationString}`);
    },
    getQuoteNew(data, page) {
        let paginationString = generatePaginationString(page);

        Object.entries(data).forEach(([key, item]) => {
            if (key === "availableDate" || key === "expiredDate") {
                if (item !== undefined && item !== null && item !== "") {
                    data[key] = moment(item).format("YYYY-MM-DD");
                }
            }
        });

        return simplePostRequest(`/quote/new?${paginationString}`, data);
    },
    getQuoteEdit(identifier, data, page) {
        let paginationString = generatePaginationString(page);

        Object.entries(data).forEach(([key, item]) => {
            if (key === "availableDate" || key === "expiredDate") {
                if (item !== undefined && item !== null && item !== "") {
                    data[key] = moment(item).format("YYYY-MM-DD");
                }
            }
        });

        return simplePostRequest(`/quote/${identifier}/edit?${paginationString}`, data);
    },
    saveQuoteDocsUploads(identifier, data, config) {
        return uploadFiles(`/quote/file/${identifier}/upload`, data, config);
    },
    getFilesListByQuote(identifier, page) {
        let paginationString = generatePaginationString(page);

        return simpleGetRequest(`/quote/file/${identifier}/list?${paginationString}`);
    },
    removeQuoteFile(identifier, fileIdentifier) {
        return simpleDeleteRequest(`/quote/file/${identifier}/${fileIdentifier}/remove`);
    },
    //benchmark
    listBenchmarks(filters, page, cancelTokenSource) {
        let filterString = generateFilterString(filters);
        let paginationString = generatePaginationString(page);

        return simpleGetRequest(`/benchmark/list?${filterString}${paginationString}`, null, cancelTokenSource);
    },
    // Profile
    getProfile() {
        return api
            .get("/profile/show", {
                headers: {
                    Authorization: "Bearer " + (isClient ? window.localStorage.getItem("session-token") : ""),
                },
            })
            .then(response => {
                return {
                    role: response.data.role,
                    identifier: response.data.identifier,
                    name: response.data.name,
                    email: response.data.email,
                    cellphone: response.data.cellphone,
                    phone: response.data.phone,
                    roleHumanReadable: response.data.roleHumanReadable,
                    permissions: response.data.permissions,
                    picture: response.data.profilePictureUri
                        ? process.env.REACT_APP_BASE_URL + response.data.profilePictureUri
                        : null,
                };
            });
    },
    editProfile(data) {
        return simplePostRequest(`/profile/edit`, data);
    },
    editPassword(data) {
        return simplePostRequest(`/profile/change-password`, data);
    },
    getProfileNotificationPreference() {
        return simpleGetRequest(`/profile/notifications`);
    },
    editProfileNotificationPreference(data) {
        return simplePostRequest(`/profile/notifications`, data);
    },
    checkProcessPriority(identifier) {
        return simpleGetRequest(`/profile/favourite-process/${identifier}/check`);
    },
    listProcessPriority() {
        return simpleGetRequest(`/profile/favourite-process/list`);
    },
    saveProcessPriority(identifier, isPriority) {
        if (isPriority) {
            return simplePostRequest(`/profile/favourite-process/add`, {
                process: identifier,
            });
        } else {
            return simplePostRequest(`/profile/favourite-process/remove`, {
                process: identifier,
            });
        }
    },
    uploadClientCertificateFile(data) {
        return simplePostRequest(`/client-certificate/upload`, data);
    },
    getCertificateList(filters) {
        let filterString = generateFilterString(filters);

        return simpleGetRequest(`/client-certificate/list?${filterString}`);
    },
    uploadProfilePicture(data) {
        return simplePostRequest(`/profile/picture`, data);
    },
    // User
    listUsers(filters, page, pageSize) {
        let filterString = generateFilterString({ search: filters?.search });

        let selected = generateFilterString({
            filters: { ...filters?.filters },
            client: filters?.client,
            role: filters?.role,
            auto: filters?.auto ? { ...filters?.auto } : null,
        });

        let paginationString = generatePaginationString(page);
        let pageSizeString = generatePageSizeString(pageSize);

        return simpleGetRequest(`/adm/user/list?role[]=${filters?.role}&${filterString}${paginationString}${selected}${pageSizeString}`);
    },
    showUser(identifier) {
        return simpleGetRequest(`/adm/user/${identifier}/show`).then(data => {
            return {
                role: data.role,
                identifier: data.identifier,
                name: data.name,
                email: data.email,
                cellphone: data.cellphone,
                phone: data.phone,
                roleHumanReadable: data.roleHumanReadable,
                picture: process.env.REACT_APP_BASE_URL + data.profilePictureUri,
                hasAllClients: data.hasAllClients,
            };
        });
    },
    deleteUser(identifier, filters) {
        let filterString = generateFilterString(filters);
        return simplePostRequest(`/adm/user/${identifier}/chage-status?${filterString}`);
    },
    addUser(modality, data) {
        return simplePostRequest(`/adm/user/${modality}/new`, data);
    },
    editUser(identifier, data) {
        return simplePostRequest(`/adm/user/${identifier}/edit`, data);
    },
    editUserPassword(identifier, data) {
        return simplePostRequest(`/adm/user/${identifier}/change-password`, data);
    },
    editUserRole(identifier, data) {
        return simplePostRequest(`/adm/user/${identifier}/change-role`, data);
    },
    showUserClients(identifier, filters, page, pageSize) {
        let filterString = generateFilterString({ search: filters?.search });
        let paginationString = generatePaginationString(page);
        let pageSizeString = generatePageSizeString(pageSize);

        return simpleGetRequest(`/adm/user/${identifier}/clients?${pageSizeString}${filterString}${paginationString}`);
    },
    showUserPermissions(identifier) {
        return simpleGetRequest(`/adm/user/${identifier}/permissions`);
    },
    saveProfilePermissions(identifier, data) {
        return simplePostRequest(`/adm/user/${identifier}/permissions`, data);
    },
    saveProfileClients(identifier, data) {
        return simplePostRequest(`/adm/user/${identifier}/clients`, data);
    },
    addAllProfileClients(identifier) {
        return simplePostRequest(`/adm/user/${identifier}/clients/all`, {});
    },
    removeAllProfileClients(identifier) {
        return simplePostRequest(`/adm/user/${identifier}/clients/remove-all`, {});
    },
    uploadUserPicture(identifier, data) {
        return simplePostRequest(`/adm/user/${identifier}/picture`, data);
    },
    addVehicle(data) {
        return simplePostRequest(`/adm/vehicle/new`, data);
    },
    editVehicle(identifier, data) {
        return simplePostRequest(`/adm/vehicle/${identifier}/edit`, data);
    },
    showVehicle(identifier) {
        return simpleGetRequest(`/adm/vehicle/${identifier}/show`);
    },
    //Stage
    getStagesList() {
        return simpleGetRequest("/stage/list");
    },
    // Ports
    getPortList() {
        return simpleGetRequest("/port/list");
    },
    //Docs Upload
    getProcessFileModalities(stageModality) {
        var searchString = "?";

        if (stageModality) {
            searchString += "stageModality=" + stageModality;
        }

        return simpleGetRequest("/process/file/modality/list" + searchString);
    },
    getFilesListByProcess(identifier, page) {
        let paginationString = generatePaginationString(page);

        return simpleGetRequest(`/process/file/${identifier}/list?${paginationString}`);
    },
    saveDocsUploads(identifier, data, config) {
        return uploadFiles(`/process/file/${identifier}/upload`, data, config);
    },
    // Processo Externo
    saveProcessManual(data, config) {
        return uploadFiles(`/process/file/manual`, data, config);
    },
    removeFile(processIdentifier, fileIdentifier) {
        return simpleDeleteRequest(`/process/file/${processIdentifier}/${fileIdentifier}/remove`);
    },
    downloadFile(processIdentifier, fileIdentifier) {
        return api
            .get(`process/file/${processIdentifier}/${fileIdentifier}/download`, {
                responseType: "arraybuffer",
                headers: {
                    Authorization: "Bearer " + (isClient ? window.localStorage.getItem("session-token") : ""),
                },
            })
            .then(response => {
                return response.data;
            });
    },
    downloadFreightXml(identifier) {
        return api
            .get(`/freight/${identifier}/download-cte`, {
                responseType: "arraybuffer",
                headers: {
                    Authorization: "Bearer " + (isClient ? window.localStorage.getItem("session-token") : ""),
                },
            })
            .then(response => {
                return response.data;
            });
    },

    //CTE
    uploadCTE(data, identifier) {
        return simplePostRequest(`/freight/${identifier}/upload-cte`, data);
    },
    removeCTEFile(identifier) {
        return simplePostRequest(`/freight/${identifier}/remove-cte`);
    },
    // Especificação da operação
    listOperation(filters, page, pageSize = 10) {
        let filterString = generateFilterString(filters);
        let paginationString = generatePaginationString(page);

        return simpleGetRequest(`/operation-specification/list?${filterString}${paginationString}page_size=${pageSize}`);
    },
    showOperation(identifier) {
        return simpleGetRequest(`/operation-specification/${identifier}/show`);
    },
};

export default requests;
